import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Funding" description="Development is funded by React Training workshops, private training and online courses" mdxType="SEO" />
    <h1 {...{
      "id": "funding"
    }}>{`Funding`}</h1>
    <p>{`Development is primarily funded by `}<a parentName="p" {...{
        "href": "https://reacttraining.com"
      }}>{`React Training`}</a>{` workshops, private training and online courses.`}</p>
    <p>{`If you are interested in supporting the continued development of Reach UI, please consider:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://opencollective.com/reach-ui"
        }}>{`Donating to the Open Collective`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://reacttraining.com/courses/"
        }}>{`Subscribing to online courses`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://reacttraining.com/workshops/"
        }}>{`Attending a workshop`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://reacttraining.com/training/"
        }}>{`Getting private training`}</a></li>
      <li parentName="ul">{`Emailing `}<a parentName="li" {...{
          "href": "mailto:hello@reacttraining.com"
        }}>{`hello@reacttraining.com`}</a>{` for direct corporate sponsorship`}</li>
    </ul>
    <p>{`Thank you!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      